import React from 'react';
import {
    IWizardStep,
    WizardStepType,
} from '@frontend/jetlend-web-ui/src/components/Wizard/WizardBreadCrumbButton';
import { SectionStretchType } from '@app/ui/Section/Section';
import { AnimationType } from '@app/ui/animations';
import yandexIcon from '@app/icons/yandex-icon.svg';
import googlePlayIcon from '@app/icons/google-play-icon.svg';
import appStoreIcon from '@app/icons/app-store-icon.svg';
import { Department } from '@app/models/features/aboutPlatform';

export enum SectionType {
    EXTERNAL_NEWS = 'external_news',
    PARTNERS = 'partners',
    FEEDBACKS = 'feedbacks',
    YOUTUBE = 'youtube',
    STATISTICS = 'statistics',
    INDICATORS = 'indicators',
    MARKUP = 'markup',
    MARKUP_BLOCKS = 'markup_blocks',
    ROLLUP = 'rollup',
    STEPS = 'steps',
    REPORTS = 'reports',
    CALCULATOR = 'calculator',
    FAQ = 'faq',
    HOW_IT_WORKS = 'how_it_works',
    CONDITIONS = 'conditions',
    FEATURES = 'features',
    GEOGRAPHY = 'geography',
    COMPANIES = 'companies',
    CHART = 'chart',
    DIRECTORS = 'directors',
    EMPLOYEE = 'employee',
    DOCUMENTS = 'documents',
    CONTACTS = 'contacts',
    LINKS = 'links',
    NEWS = 'news',
    FEEDBACK_PLATFORMS = 'feedback_platforms',
    BANNER = 'banner',
    BROKERS = 'brokers',
}

export type Params = { slug: string }
export type SearchParams = { [key: string]: string | string[] | undefined }

export interface IPageProps {
    params?: Params;
    searchParams?: SearchParams;
}

export interface ISectionBasedProps<ISectionApiModel> {
    section: ISectionApiModel;
    filled?: boolean;
}

/**
 * Интерфейс стандартной секции с описанием
 */
export interface BaseSectionWithBlocksAndDescription<T> extends BaseSectionWithBlocks<T> {
    /**
     * Описание
     */
    description: string;
}

export type SectionConfigType = Partial<Record<SectionType, ISectionConfig>>

export interface ISectionConfig {
    component: React.ComponentType;
    hideTitle?: boolean;
    filled?: boolean;
    stretch?: SectionStretchType;
    action?: React.ReactNode | JSX.Element;
    /**
     * Дополнительное действие для мобильной версии
     */
    mobileAction?: React.ReactNode | JSX.Element;
    animation?: AnimationType;
}

export type ExtendedBaseSectionApiModel = IBaseSectionApiModel & {
    [p: string]: any;
}

export interface BaseSectionWithBlocks<TBlock, TType = SectionType> extends IBaseSectionApiModel<TType> {
    blocks: TBlock[];
}

export type BaseSectionWithItems<TItem> = IBaseSectionApiModel & {
    items: TItem[];
}

export type BaseSectionWithDocuments<TItem> = IBaseSectionApiModel & {
    documents: TItem[];
    allowFilter?: boolean;
}

export interface IReportsSectionReportModel {
    title: string;
    icon: string;
    value: string;
    url: string;
}

export interface IBaseSectionWithReportsModel extends IBaseSectionApiModel {
    price: string;
    reports: IReportsSectionReportModel[];
}

export enum ClientType {
    INVESTOR = 'investor',
    BORROWER = 'borrower',
    PARTNER = 'partner',
    INVESTMENTS = 'investments',
    ACADEMY = 'academy',
}

export interface IAppBadge {
    src: string;
    href: string;
    title: string;
}

export const CLIENT_TYPE_SCOPE_TITLE: Partial<Record<ClientType, string>> = {
    [ClientType.INVESTOR]: 'инвестор',
    [ClientType.BORROWER]: 'заемщик',
    [ClientType.PARTNER]: 'партнер',
};

export const CLIENT_TYPE_SCOPE_MAP: Partial<Record<ClientType, string>> = {
    [ClientType.INVESTOR]: 'client',
    [ClientType.BORROWER]: 'business',
    [ClientType.PARTNER]: 'partner',
};

export const CLIENT_TYPE_LOGIN_URLS: Partial<Record<ClientType, string>> = {
    [ClientType.INVESTOR]: '/invest/login/',
    [ClientType.BORROWER]: '/lend/login/',
    [ClientType.PARTNER]: '/partner/login/',
};

export const CLIENT_TYPE_RESET_PASS_URLS: Partial<Record<ClientType, string>> = {
    [ClientType.INVESTOR]: '/invest/reset/',
    [ClientType.BORROWER]: '/lend/reset/',
    [ClientType.PARTNER]: '/partner/reset/',
};

export const CLIENT_TYPE_REGISTRATION_URLS: Partial<Record<ClientType, string>> = {
    [ClientType.INVESTOR]: '/invest/register/',
    [ClientType.BORROWER]: '/lend/register/',
    [ClientType.PARTNER]: '/partner/register/',
};

export const CLIENT_TYPE_CREATE_URLS: Partial<Record<ClientType, string>> = {
    [ClientType.INVESTOR]: '/invest/create/',
    [ClientType.BORROWER]: '/lend/create/',
    [ClientType.PARTNER]: '/partner/create/',
};

export const CLIENT_TYPE_CABINET_URLS: Partial<Record<ClientType, string>> = {
    [ClientType.INVESTOR]: '/invest/v3',
    [ClientType.BORROWER]: '/lend/v3',
    [ClientType.PARTNER]: '/partner/v3',
};

export interface IFaqQueryApiModel {
    category?: string;
    limit?: number;
    offset?: number;
}

export enum CommonStage {
    Phone = 'phone',
    Sms = 'sms',
    ResetPassword = 'reset_password',
    Email = 'email',
    Loading = 'loading',
    NewPassword = 'new_password',
    Login = 'login',
    Create = 'create',
    Registration = 'registration',
    OauthPhone = 'oauth_phone',
}

export const getClientTitle = (clientType: string) => clientType ? `${clientType}a` : '';

export function getClientTypeFromUrl(url: string): ClientType|undefined {
    if (!url) {
        return undefined;
    }

    if (url.includes('/invest/')) {
        return ClientType.INVESTOR;
    }

    if (url.includes('/lend/')) {
        return ClientType.BORROWER;
    }

    if (url.includes('/partner/')) {
        return ClientType.PARTNER;
    }

    return undefined;
}

export const getCommonSteps = (stage: CommonStage): IWizardStep[] => ([
    {
        id: 0,
        title: 'Ввод телефона',
        type: WizardStepType.Completed,
    },
    {
        id: 1,
        title: 'Подтверждение телефона',
        type: (stage === CommonStage.Sms || stage === CommonStage.Email) && WizardStepType.Completed,
    },
    {
        id: 3,
        title: 'Ввод email',
        type: stage === CommonStage.Email && WizardStepType.Completed,
    },
]);

/**
 * Ссылки на страницы с тарифами клиентов
 */
export const CLIENT_TYPE_CABINET_TARIFF_URLS: Partial<Record<ClientType, string>> = {
    [ClientType.INVESTOR]: '/invest/v3/profile/tariffs',
    [ClientType.BORROWER]: '/lend/v3/statuses',
    [ClientType.PARTNER]: '',
};

/**
 * Наименования типов клиентов
 */
export const CLIENT_TYPE_NAMES: Partial<Record<ClientType, string>> = {
    [ClientType.INVESTOR]: 'Инвестор',
    [ClientType.BORROWER]: 'Заемщик',
    [ClientType.PARTNER]: 'Партнер',
};

export interface IAuthorApiModel {
    name: string;
    icon: string;
}

type IFeedbacksService = 'Яндекс' | 'Google Play' | 'App Store';

export const SERVICE_ICONS_MAP: Record<IFeedbacksService, string> = {
    'Яндекс': yandexIcon,
    'Google Play': googlePlayIcon,
    'App Store': appStoreIcon,
};

/**
 * Типы фича блоков
 */
export enum HistoryFeatureType {
    YEAR_2019 = 'year_2019',
    YEAR_2020 = 'year_2020',
    YEAR_2021 = 'year_2021',
    YEAR_2022 = 'year_2022',
    YEAR_2023 = 'year_2023',
    YEAR_2024 = 'year_2024',
}

export interface IPhoneFormModel {
    /**
     * Номер телефона
     */
    phone: string;
}

export interface IBaseSectionApiModel<TType = SectionType> {
    type: TType;
    title: string;
    /**
     * ID заголовка секции
     */
    title_id?: string;
    title_mobile?: string;
    description?: string;
    show_registration_form?: boolean;
    hidden: boolean;
}

export interface IBasePerson {
    photo: string | false;
    name: string;
    department?: Exclude<Department, 'all'>;
    position: string;
    email: string;
    description?: string;
}

export interface IStatisticsBlockApiModel {
    /**
     * Заголовок
     */
    title: string;
    /**
     * Значение
     */
    value: string;
    /**
     * Ссылка
     */
    value_url?: string;
    /**
     * Описание
     */
    description: string;
}

export interface IBrokerModel {
    /**
     * Идентификатор
     */
    id: string;
    /**
     * Заголовок
     */
    title: string;
    /**
     * Иконка
     */
    icon?: string;
    /**
     * Ссылка
     */
    url?: string;
}
